import React, { Fragment, useEffect, useState } from "react";
import Title from "../../../text/title";
import Toaster from "../../../common/Toaster";
import LinkText from "../../../text/linkText";
import RoundedBtn from "../../../button/roundedBtn";
import "./style.scss";
import OptionCard from "../../optionCard";
import Input from "../../../input";
import NavRight from "../../../button/navRight";
import NavLeft from "../../../button/navLeft";
import { Link, navigate } from "gatsby";
import { connect } from "react-redux";
import { updateCurrentBrand as updateBrand } from "../../../../actions/brand/action";
import { brandAge as brandAgeConstant } from "../../../../util/constants";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/customer-form-lottie.json";

const StepFive = ({ updateBrand, age, brandId }) => {
  const [isMobile, setMobile] = useState(false);
  const [localstorageUserState, setLocalstorageUserState] = useState({});
  const [brandAge, setBrandAge] = useState("");
  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const options = ["Lounge", "Athleisure", "Street"];

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const localstorageUser = JSON.parse(
      localStorage.getItem("registeringUser")
    );
    setLocalstorageUserState(localstorageUser);
    setBrandAge(
      localstorageUser && localstorageUser.brandType
        ? localstorageUser.brandType
        : ""
    );

    if (!localstorageUser) {
      if (!localstorageUser || !localstorageUser.name) {
        navigate("/onboarding/step-one");
      } else if (!localstorageUser || !localstorageUser.state) {
        navigateBack();
      }
    }
  }, []);

  const onSubmit = async () => {
    if (!brandAge) {
      setToastrMsg("Please choose!");
      doRefresh((prev) => prev + 1);
      return;
    }
    localstorageUserState.brandType = brandAge;
    localStorage.setItem(
      "registeringUser",
      JSON.stringify(localstorageUserState)
    );
    var updateBrandResonse = await updateBrand(brandId, {
      brandType: brandAge,
    });
    if (updateBrandResonse && !updateBrandResonse.error) {
      navigate("/onboarding/registration-info");
    } else {
      setToastrMsg("Unable to add Brand Type");
      doRefresh((prev) => prev + 1);
    }
  };

  const navigateBack = () => {
    navigate("/onboarding/brand-mission");
  };

  const setBrandAgeHandler = (type, value) => {
    if (type === "radio") {
      for (let ba of brandAge) {
        if (!options.includes(ba)) {
          setBrandAge("");
        }
      }
      if (brandAge.includes(value)) {
        setBrandAge("");
      } else {
        setBrandAge(value);
      }
    } else {
      setBrandAge(value);
    }
  };

  return (
    <Fragment>
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <div className="onboarding row age brand-type">
        <div className="top">
          <img src="/images/common/plus_new_logo.svg" />
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12 r-image">
          <div className="d-flex justify-content-center margin-top-img left-flex">
            {!isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 200, height: 200, margin: "auto" }}
              />
            )}
            {isMobile && (
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 120, height: 120, margin: "auto" }}
              />
            )}
            {/* <Title className="hide-mobile" text={`Products ${<br/>} interested in?`} /> */}
            <p className="hide-mobile title text-center">
              Products <br /> interested in?
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-6 col-sm-6 col-12  step-content">
          <div className="brand-focus-heading hide-desktop">
            {/* <Title text={`Products ${<br/>} interested in?`} /> */}
            <p className="hide-desktop title text-center">
              Products <br /> interested in?
            </p>
          </div>

          <div className="row option-spacing mob-spacing">
          <div className="col-6 col-sm-6 mid-option">
              <div
                className={`option-card option-med option-med-new ${brandAge === "Street"&&'select'}`}
                // style={brandAge === "Street" ? { background: "#31ffea" } : null}
                name="Street"
                onClick={() => setBrandAgeHandler("radio", "Street")}
              >
                <img className="products-intr-img" src="/images/common/signup_02.webp" />
                <div className="left-info">
                Street<br />
                <span className="opt-info less-lh">
                  Ex : Fear of God, Amiri, Kith etc.
                </span>
                </div>
              </div>
            </div>
            <div className="col-6 col-sm-6 mid-option ">
              <div
                className={`option-card option-med option-med-new ${brandAge === "Lounge"&&'select'}`}
                // style={brandAge === "Lounge" ? { background: "#31ffea" } : null}
                name="Lounge"
                onClick={() => setBrandAgeHandler("radio", "Lounge")}
              >
                
                  <img className="products-intr-img" src="/images/common/signup_01.webp" />
               
                <div className="left-info">
                  Lounge <br />
                  <span className="opt-info less-lh">
                    Ex : James Perse, Aviator nation etc.{" "}
                  </span>
                </div>
              </div>
            </div>
           
          </div>
          <div className="row option-spacing mob-spacing">
          <div className="col-6 col-sm-6 mid-option">
              <div
                className={`option-card option-med option-med-new ${brandAge === "Athleisure"&&'select'}`}
                // style={
                //   brandAge === "Athleisure" ? { background: "#31ffea" } : null
                // }
                name="Athleisure"
                onClick={() => setBrandAgeHandler("radio", "Athleisure")}
              >
                <img className="products-intr-img" src="/images/common/signup_03.webp" />
                <div className="left-info">
                Athleisure<br />
                <span className="opt-info less-lh">
                  Ex : Vuori, Lululemon, Alo yoga etc.
                </span>
                </div>
              </div>
            </div>
           
            <div className="col-6 col-sm-6 mid-option input-align">
              <input
                type="text"
                className="pi-type-own"
                placeholder="If other, type here "
                onChange={(e) => setBrandAgeHandler("text", e.target.value)}
                value={brandAge && !options.includes(brandAge) ? brandAge : ""}
              />
            </div>
          </div>

          <div className={!isMobile ? "row" : ""}>
            {/* <div className="col-12 col-md-6 col-sm-6 col-xl-6 to-right hpx"></div> */}
            <div className="col-12 margin-mobile to-left">
              <div className="nav-conatiner">
                <div>
                  <span
                    className="text-decoration-none"
                    onClick={() => navigateBack()}
                  >
                    <NavLeft />
                    <span className="nav-text">Back</span>
                  </span>
                </div>
                <div>
                  <span
                    className="text-decoration-none"
                    onClick={() => onSubmit()}
                  >
                    <span className="nav-text">Next</span>
                    <NavRight />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = ({ brand }) => ({
  age: brand.age,
  brandId: brand.brandId,
});

export default connect(mapStateToProps, { updateBrand })(StepFive);
